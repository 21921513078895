import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './views/Home';
import RequestLink from './views/RequestLink';
import './App.css';
import User from './views/User';
import Join from './views/Join';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/links/:id" component={RequestLink}>
        </Route>
        <Route path="/u/:id" component={User}>
        </Route>
        <Route path="/join/:id" component={Join}>
        </Route>
        <Route path="/" component={Home}>
        </Route>

      </Switch>
    </Router>
  );
}

export default App;
