import React from "react"
import '../../assets/tailwind.output.css';
import './style.css'

import {
    useParams
} from "react-router-dom";
import { AppStoreButton } from "../Shared/AppStoreButton";

import headerImage from '../../images/clip-free-money.png'

export default function Join(props) {
    let { id } = useParams();

    const [referralInfo, setReferralInfo] = React.useState({
        data: {
            display_name: "Apisit",
            nufi_id: "ap",
            referral_code: "test"
        },
    });

    React.useEffect(() => {
        // Update the document title using the browser API
        let url = "https://api-dev.nufi.app/referrals/" + id;
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error("HTTP status " + response.status);
                }
                return response.json()
            })
            .then(data => {
                console.log("data - ", data);
                setReferralInfo(data)
            })
            .catch(err => {
                console.log(err)
                window.location.href = "/";
            });
    }, []);


    return (
        <div className="static h-screen">
            <nav className="flex bg-green items-center justify-between flex-wrap p-6">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <span className="font-semibold text-2xl tracking-tight">nufi</span>
                </div>
            </nav>

            <div className="container mx-auto text-center">

                <div className="text-center mx-auto p-8 max-w-xl">

                    <div className="">
                        <img src={headerImage} className="h-32 mx-auto mb-8" />
                        <p className="text-3xl font-medium ">{referralInfo.data.display_name} invites you to try NuFi</p>
                        <p className="text-left text-lg mt-4">{referralInfo.data.display_name} uses NuFi to invest in Bitcoin as well as Send and Receive digital assets without hassel. You too can invest in Bitcoin and other major digital assets as low as $1.</p>
                    </div>

                </div>
                <div className=" mt-8 text-center">
                    <p className=" font-bold tracking-wider">Join the revolution today!</p>
                    {AppStoreButton()}
                </div>
            </div>

        </div>
    )
}

