import React from "react"
import '../../assets/tailwind.output.css';

import { QRCode } from "react-qr-svg";
import './style.css'
import { AppStoreButton } from "../Shared/AppStoreButton";

import {
    useParams
} from "react-router-dom";

export default function User(props) {
    let { id } = useParams();

    const [nufiUser, setNufiUser] = React.useState({
        data: {
            display_name: "Apisit",
            nufi_id: "ap",
        },
    });


    function formattedURL() {
        return "https://nufi.app/u/" + id;
    }

    React.useEffect(() => {
        let url = "https://api-dev.nufi.app/nufi/" + id;
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error("HTTP status " + response.status);
                }
                return response.json()
            })
            .then(data => {
                console.log("data - ", data);
                setNufiUser(data)
            })
            .catch(err => {
                console.log(err)
                window.location.href = "/";
            });
    }, []);

    return (
        <div className="static bg-green h-screen">
            <nav class="flex items-center justify-between flex-wrap p-6">
                <div class="flex items-center flex-shrink-0 text-white mr-6">
                    <span class="font-semibold text-2xl tracking-tight">nufi</span>
                </div>
            </nav>

            <div className="container mx-auto text-center z-10 ">
                <div className="p-6 text-white">
                    <p className="text-2xl font-bold ">{nufiUser.data.display_name}</p>
                    <p className="text-lg font-medium ">{nufiUser.data.nufi_id}</p>
                </div>
                <div className="text-center max-w-sm mx-auto p-8 bg-white rounded-lg shadow-xl">

                    <QRCode
                        className="mx-auto h-64"
                        bgColor="#FFFFFF"
                        fgColor="#000000"
                        level="Q"
                        style={{ width: 200 }}
                        value={formattedURL()}
                    />

                </div>
                <p className="text-white my-4 max-w-sm mx-auto">Send Bitcoin or any digital asset to {nufiUser.data.display_name} by scanning this QR code with NuFi App</p>
                <div className="mt-8 text-center">
                    {/* <p className="text-white font-bold tracking-wider">DOWNLOAD NUFI APP</p> */}
                    {AppStoreButton()}
                </div>
            </div>

        </div>
    )
}