import React from "react"
import '../../assets/tailwind.output.css';

import { QRCode } from "react-qr-svg";
import { AppStoreButton } from "../Shared/AppStoreButton";
import './style.css'
import {
    useParams
} from "react-router-dom";

export default function RequestLink(props) {

    const [linkData, setLinkData] = React.useState({
        data: {},
        user: {},
    });

    let { id } = useParams();

    function formattedURL() {
        return "https://nufi.app/links/" + id;
    }

    React.useEffect(() => {
        // Update the document title using the browser API
        let url = "https://api-dev.nufi.app/links/" + id;
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error("HTTP status " + response.status);
                }
                return response.json()
            })
            .then(data => {
                console.log("data - ", data);
                setLinkData(data.data)
            })
            .catch(err => {
                console.log(err)
                window.location.href = "/";
            });
    }, []);


    function currencySymbol(code) {
        return Number().toLocaleString(undefined, { style: "currency", currency: code }).slice(0, 1)
    }

    return (
        <div className="static bg-green h-screen">
            <nav className="flex items-center justify-between flex-wrap p-6">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <span className="font-semibold text-2xl tracking-tight">nufi</span>
                </div>
            </nav>

            <div className="container mx-auto text-center z-10 ">
                <div className="p-6 text-white">
                    <p className="text-4xl font-medium">{linkData.user.full_name}</p>
                    <p className="text-lg font-medium ">{linkData.user.nufi_id}</p>
                </div>
                <div className="text-center max-w-sm mx-auto p-8 bg-white rounded-lg shadow-xl">
                    <QRCode
                        className="mx-auto h-64"
                        bgColor="#FFFFFF"
                        fgColor="#000000"
                        level="Q"
                        style={{ width: 200 }}
                        value={formattedURL()}
                    />
                    <p className="inline-block"><span className="align-top leading-loose  text-2xl">{currencySymbol(linkData.fiat_currency ? linkData.fiat_currency : "USD")}</span><span className="text-4xl">{linkData.fiat_amount}</span></p>
                    {
                        linkData.data.message != "" ?
                            <p className="italic text-gray-600">"{linkData.data.message}"</p>
                            :
                            null
                    }

                </div>
                <div className=" mt-8 text-center">
                    <p className="text-white font-bold tracking-wider">SCAN TO PAY WITH NUFI APP</p>
                    {AppStoreButton()}
                </div>
            </div>

        </div>
    )
}